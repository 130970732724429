import React, { Component } from 'react';
import { Form, InputNumber, Switch, Select, Space, Divider, Tabs, Upload, Tooltip, Button, Image, Input } from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

class SliderEditorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabActive: '2'
        };
    }

    onChange = (key) => {
        this.setState({ tabActive: key });
    };

    handleFormChange = (changedValues, allValues) => {
        const processedValues = this.processValues(allValues);
        this.props.handleSubmit(processedValues);
    };

    processValues = (values) => {
        const filteredValues = Object.entries(values).reduce((acc, [key, value]) => {
            if (value !== undefined) {
                if (key === 'centerPadding' && !isNaN(value)) {
                    acc[key] = `${value}px`;
                } else {
                    acc[key] = value;
                }
            }
            return acc;
        }, {});
        return filteredValues;
    }

    renderFormItems = (items) => {

        return items.map(item => {
            let style = item.style ? item.style : {};
            return <Form.Item label={this.labelWithTooltip(item)} style={style} name={item.name} key={item.name}>
                {this.renderInput(item)}
            </Form.Item>
        });
    }

    labelWithTooltip = (item) => {
        if (!item.tooltip) {
            return item.label
        }
        return <span>{item.label}   <Tooltip title={item.tooltip} color='#454e5d'>
            <sup><QuestionCircleOutlined /></sup>
        </Tooltip> </span>
    }

    renderInput = (item) => {
        switch (item.type) {
            case 'text':
                return (
                    <Input style={{ width: "100%" }} />
                );

            case 'textarea':
                return(
                    <Input.TextArea style={{ width: "100%" }}/>
                );
            case 'number':
                return (
                    <InputNumber style={{ width: "100%" }} min={item.min ? item.min : null} addonAfter={item.suffix && this.renderSuffix(item.suffix)} />
                );
            case 'switch':
                return <Switch />;
            case 'select':
                return (
                    <Select style={{ width: "100%" }}>
                        {item.options.map(option => (
                            <Option value={option.value} key={option.value} disabled={option.disabled?option.disabled:false}>{option.label}</Option>
                        ))}
                    </Select>
                );
            case 'color':
                return <input type="color" style={{ border: '0px' }} />;
            case 'file':
                return (
                    <>
                        <Upload
                            itemRender={() => null}
                            maxCount={1}
                              accept=".png"
                            beforeUpload={file => {
                                this.props.handleFileUpload(file, item.name);
                                return false;
                            }}
                        >
                            <Button><UploadOutlined />Upload</Button>
                        </Upload>
                        {this.props.buttonImageUrls[`${item.name}Url`] && (
                            <>
                                <Image
                                    src={this.props.buttonImageUrls[`${item.name}Url`]}
                                    alt={item.label}
                                    style={{ width: '100px', marginTop: '10px' }}
                                />
                                <Button
                                    onClick={() => this.props.handleFileRemove(item.name)}
                                    style={{ display: 'block', marginTop: '10px' }}
                                >
                                    Remove
                                </Button>
                            </>
                        )}
                    </>
                );
            default:
                return null;
        }
    }


    renderSuffix = (suffix) => (
        <Form.Item name={suffix.name} noStyle>
            <Select style={{ width: 70 }}>
                {suffix.options.map(option => (
                    <Option value={option.value} key={option.value}>{option.label}</Option>
                ))}
            </Select>
        </Form.Item>
    )

    renderFormSections = (sections) => {
        return sections.map((section, index) => (
            <div key={index}>
                <Divider>{section.title}</Divider>
                <Space size='large' wrap>
                    {this.renderFormItems(section.items)}
                </Space>
            </div>
        ));
    }

    render() {
        const basicDefinition = [{
            title: "Definice Slideru",
            items: [
                {
                    name: 'name',
                    label: 'Jméno',
                    type: 'text',
                    rules: [
                        {
                            required: true,
                            message: 'Prosím vložte jméno Slideru!',
                        },
                    ],
                    tooltip: 'Vámi zvolené jméno Slideru',
                    style: { width: '400px' }
                },
                {
                    name: ['extra_info', 'type'],
                    label: 'Typ Slideru',
                    type: 'select',
                    options: [
                        {
                            value: 'change',
                            label: 'Změnit stávající Slider',
                        },
                        {
                            value: 'create',
                            label: 'Vytvořit nový Slider',
                            disabled: true,
                        },
                    ],
                    rules: [
                        {
                            required: true,
                            message: 'Prosím zvolte typ slideru!',
                        },
                    ],
                    tooltip: 'Zvolte požadovaný typ slideru podle toho, zda chcete upravit Slider již existující nebo vytvořit vlastní nový',
                    style: { width: '400px' }
                },
                {
                    name: ['settings', 'link'],
                    label: 'Odkaz',
                    type: 'text',
                    rules: [
                        {
                            required: true,
                            message: 'Prosím vložte odkaz!',
                        },
                    ],
                    tooltip: 'Vložte dokaz na stránku, kde se nachází Váš slider',
                    style: { width: '400px' }
                },
                {
                    name: ['settings', 'selector'],
                    label: 'Selector',
                    type: 'text',
                    rules: [
                        {
                            required: true,
                            message: 'Prosím vložte selector!',
                        },
                    ],
                    tooltip: 'Vložte selector vašeho slideru. Pokud jde o class, přidejte na začátek tečku (.selector). Pokud jde o ID, přidejte hashtag (#selector)',
                    style: { width: '400px' }
                },
                {
                    name: ['extra_info', 'description'],
                    label: 'Popis',
                    type: 'textarea',
                    tooltip: 'Místo pro vlastní popis slideru',
                    style: { width: '400px' }
                }
            ]
        }]




        const styleDefinition = [
            {
                title: "Nastavení stylů",
                items: [

                    { name: 'colorDot', label: 'Barva bodů', type: 'color', tooltip: 'Vyberte základní barvu bodů' },
                    { name: 'colorDotActive', label: 'Barva aktivního bodu', type: 'color', tooltip: 'Vyberte barvu aktivního bodu' },
                    { name: 'sizeDot', label: 'Velikost bodů', type: 'number', tooltip: 'Vyberte velikost bodů' },
                    { name: 'sizeButtons', label: 'Velikost šipek', type: 'number', tooltip: 'Vyberte velikost šipek' },
                    { name: 'posLeftBtn', label: 'Pozice levé šipky', type: 'number', tooltip: 'Horizontální pozice levé šipky relativně od levého okraje. Kladné hodnoty způsobí posun vpravo, záporné vlevo' },
                    { name: 'posRightBtn', label: 'Pozice pravé šipky', type: 'number', tooltip: 'Horizontální pozice pravé šipky relativně od pravého okraje. Kladné hodnoty způsobí posun vlevo, záporné vpravo' },
                    { name: 'posTopBtn', label: 'Vertikální pozice šipek', type: 'number', tooltip: 'Vertikální pozice obou šipek relativně od horního okraje. Kladné hodnoty způsobí posun dolů, záporné nahoru' },

                ]
            },
            {
                title: "Vlastní šipky",
                items: [
                    { name: 'leftButtonImage', label: 'Obrázek levé šipky', type: 'file', tooltip: 'Vyberte a nahrajte si vlastní obrázek levé šipky' },
                    { name: 'rightButtonImage', label: 'Obrázek pravé šipky', type: 'file', tooltip: 'Vyberte a nahrajte si vlastní obrázek pravé šipky' },
                    {
                        name: 'width',
                        label: 'Šířka Slideru',
                        suffix: {
                            name: 'suffixW',
                            options: [
                                { value: 'px', label: 'px' },
                                { value: '%', label: '%' },
                                { value: 'vw', label: 'vw' }
                            ]
                        },
                        type: 'number',
                        tooltip: 'Šířka Slideru',
                        style: { visibility: 'hidden' }
                    },
                    {
                        name: 'height',
                        label: 'Výška Slideru',
                        suffix: {
                            name: 'suffixH',
                            options: [
                                { value: 'px', label: 'px' },
                                { value: 'vh', label: 'vh' }
                            ]
                        },
                        type: 'number',
                        tooltip: 'Nastavení výsky Slideru, px - přepočtený pixel, CSS pixel, vh - procento z výšky okna prohlížeče',
                        style: { visibility: 'hidden' }
                    },
                    {
                        name: 'objectFit',
                        label: 'Object fit',
                        type: 'select',
                        tooltip: 'Tímto nastavením ovlivníte způsob, jakým se mají obrázky přizpůsobit slideru',
                        options: [
                            { value: 'contain', label: 'obsáhnout' },
                            { value: 'cover', label: 'cover' },
                            { value: 'fill', label: 'vyplnit' },
                            { value: 'none', label: 'původní velikost' },
                            { value: 'scale-down', label: 'zmenšit' }
                        ],
                        style: { visibility: 'hidden' }
                    },

                ]
            }
        ];

        const settingsDefinition = [
            {
                title: "Základní funkce",
                items: [
                    { name: 'arrows', label: 'Šipky', type: 'switch', tooltip: 'Zobrazit nebo schovat šipky' },
                    { name: 'dots', label: 'Body', type: 'switch', tooltip: 'Zobrazit nebo schovat body' },
                    { name: 'autoplay', label: 'Přehrávání', type: 'switch', tooltip: 'Zapnout automatické přehrávání. Doporučujeme zároveň zapnout Nekonečnou smyčku' },
                    { name: 'vertical', label: 'Vertikálně', type: 'switch', tooltip: 'Přepne na vertikální scrolování obrázků' },
                    { name: 'fade', label: 'Prolínání', type: 'switch', tooltip: 'Přepnout na prolínání obrázků. Změna animace ze scrolování na prolínání' },
                    { name: 'infinite', label: 'Nekonečná smyčka', type: 'switch', tooltip: 'Zapnout nekonečné scrolování. V případě zapnutého přehrávání doporučujeme zapnout i nekonečnou smyčku' }
                ]
            },
            {
                title: "Swajpování",
                items: [
                    { name: 'verticalSwiping', label: 'Vertikální swajp', type: 'switch', tooltip: 'Přepne na swajpování po vertikále' },
                    { name: 'swipeToSlide', label: 'Povolit swajp', type: 'switch', tooltip: 'Povolí posun obrázků pomocí swajpu' },
                    { name: 'touchMove', label: 'Posun dotykem', type: 'switch', tooltip: 'Umožní obrázek prstem či kurzorem "chytit" a posouvat' }
                ]
            },
            {
                title: "Slajdy",
                items: [
                    { name: 'slidesToShow', label: 'Zobrazovat', type: 'number', min: 1, tooltip: 'Počet slajdů zobrazených najednou' },
                    { name: 'slidesToScroll', label: 'Scrolovat', type: 'number', min: 1, tooltip: 'Kolik slajdů se posune na jeden swajp. V případě zobrazení více slajdů lze scrolovat více slajdů najednou' },
                    { name: 'speed', label: 'Rychlost (ms)', type: 'number', tooltip: 'Časový interval trvání animace přesunutí nebo prolnutí obrázků v milisekundách (1000 milisekund = 1 sekunda)' },
                    { name: 'autoplaySpeed', label: 'Interval přehrávání (ms)', type: 'number', min: 0, tooltip: 'Prodleva mezi jednotlivými slajdy v režimu automatického přehrávání v milisekundách (1000 milisekund = 1 sekunda)' }
                ]
            },
            {
                title: "Pauza",
                items: [
                    { name: 'pauseOnFocus', label: 'Pauza při kliknutí', type: 'switch', tooltip:'Automatické přehrávání se přeruší, pokud dojde ke kliknutí do prostoru slideru. Jakmile dojde ke kliknutí jinam, přehrávání se opět obnoví' },
                    { name: 'pauseOnHover', label: 'Pauza na obrázku', type: 'switch', tooltip: 'Automatické přehrávání se přeruší při najetí kurzoru do prostoru obrázku slideru' },
                    { name: 'pauseOnDotsHover', label: 'Pauza na bodech', type: 'switch', tooltip: 'Automatické přehrávání se přeruší při najetí kurzoru do prostoru bodů' }
                ]
            },
            // {
            //     title: "Centrování",
            //     items: [
            //         { name: 'centerMode', label: 'Centrování', type: 'switch', tooltip:'Povolí mód centrování, ten umožňuje zobrazovat omezenou část sousedního obrázku' },
            //         { name: 'centerPadding', label: 'Padding', type: 'number', tooltip:'Vyšší číslo zobrazí větší část sousedního obrázku' }
            //     ]
            // }
        ];
        console.log(this.props.sliderSettings, 'settings');
        console.log(this.props.sliderStyle, 'settingsSTYLE');
        let form1 = <Form layout="vertical" initialValues={this.props.basicSettings} onValuesChange={this.props.changeBasicSettings}>
            {this.renderFormSections(basicDefinition)}
        </Form>;
        let form2 = <Form layout="vertical" initialValues={this.props.sliderSettings} onValuesChange={this.handleFormChange}>
            {this.renderFormSections(settingsDefinition)}
        </Form>;
        let form3 = <Form layout="vertical" initialValues={this.props.sliderStyle} onValuesChange={this.props.handleSliderStyleChange}>
            {this.renderFormSections(styleDefinition)}
        </Form>;

        const items = [
            {
                key: '1',
                label: 'Základní',
                children: form1,
            },
            {
                key: '2',
                label: 'Funkce',
                children: form2,
            },
            {
                key: '3',
                label: 'Styly',
                children: form3,
            },

        ];

        return (
            <div style={{ maxHeight: "90vh", overflow: 'auto', margin: '0 auto', padding: "20px" }}>
                <h2 style={{ textAlign: 'center',marginBottom:'20px' }}>{this.props.title}</h2>
                <Tabs defaultActiveKey="2" type="card" items={items} onChange={this.onChange} />


            </div>
        );
    }
}

export default SliderEditorForm;
