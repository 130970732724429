import React, { Component } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import next from "../../img/next.png";
import prev from "../../img/prev.png";

class ResponsiveSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSettings: props.defaultSettings,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultSettings !== this.props.defaultSettings) {
      this.setState({ currentSettings: this.props.defaultSettings });
    }
  }

  render() {
    const { images, maxWidth, sliderStyle, buttonImageUrls } = this.props;
    const { currentSettings } = this.state;

    const StyledSlider = styled(Slider)`
      width: 100%;
      max-width: ${maxWidth};
      margin: 20px auto;

      .slick-slide div {
        width: 100%;
        height: auto;
        margin: 0 auto;
        overflow: hidden;
      }

      .slick-slide div img {
        width: 100%;
        height: auto;
        inset: 0;
        object-fit: contain;
      }

      .slick-arrow {
        position: absolute;
        top: ${sliderStyle.posTopBtn + '%'};
        z-index: 1;
      }

      .slick-next {
        z-index: 1;
        width: ${sliderStyle.sizeButtons + 'px'};
        height: ${sliderStyle.sizeButtons + 'px'};
        background-image: url(${buttonImageUrls.rightButtonImageUrl || next});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        right: ${sliderStyle.posRightBtn + '%'};
      }

      .slick-prev {
        z-index: 1;
        width: ${sliderStyle.sizeButtons + 'px'};
        height: ${sliderStyle.sizeButtons + 'px'};
        background-image: url(${buttonImageUrls.leftButtonImageUrl || prev});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        left: ${sliderStyle.posLeftBtn + '%'};
      }

      .slick-next:before {
        content: none;
      }

      .slick-prev:before {
        content: none;
      }

      .slick-dots li.slick-active button:before {
        color: ${sliderStyle.colorDotActive};
      }

      .slick-dots li button:before {
        font-size: ${sliderStyle.sizeDot + 'px'};
        color: ${sliderStyle.colorDot};
        opacity: 1;
      }
    `;

    return (
      <div style={{ width: '100%', maxWidth: maxWidth, margin: '0 auto' }}>
           <h3 style={{marginTop:'20px',marginLeft:'35px'}}>Demo</h3>
        <StyledSlider {...currentSettings} className="slickSlider">
          {images.map((image, index) => (
            <img src={image} alt={`Slide ${index + 1}`} key={index} />
          ))}
        </StyledSlider>
      </div>
    );
  }
}

export default ResponsiveSlider;
