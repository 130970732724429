import React from 'react';
import './footer.css';
import logo from '../img/logoWhite.svg';

const Footer = () => {
  return (
    <>
      <svg width="100%" height="15vh" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" overflow="auto" style={{zIndex:'2'}} shape-rendering="auto" fill="#f9f9ff">
        <defs>
          <path id="wavepath" d="M 0 2000 0 500 Q 150 207 300 500 t 300 0 300 0 300 0 300 0 300 0  v1000 z" />
          <path id="motionpath" d="M -600 0 0 0" />
        </defs>
        <g >
          <use xlinkHref="#wavepath" y="-21" fill="#bacaea">
            <animateMotion
              dur="5s"
              repeatCount="indefinite">
              <mpath xlinkHref="#motionpath" />
            </animateMotion>
          </use>
        </g>
      </svg>
      <footer className="footer">
        <div className="container">
          <div className="footer__row">
            <div className="footer__col">
              <div className="navbar-brand text-dark">
                <img src={logo} width="200" alt="Royal Fox" />
              </div>
              <p className="footer_description">
                Specializujeme se na inovace a moderní technologie od programování webových aplikací až po grafiku a design.
              </p>
            </div>
            <div className="footer__links">
              <div className="footer__links-col">
                <h6 className="footer__links-title">Užitečné odkazy</h6>
                <ul className="footer_buttons">
                  <li ><a href="/o-nas" className="footer_button">O nás</a></li>
                  <li ><a href="/gdpr" className="footer_button">GDPR</a></li>
                </ul>
              </div>
              <div className="footer__links-col">
                <h6 className="footer__links-title">Sociální sítě</h6>
                <ul className="footer_buttons">
                  <li ><a href="https://www.facebook.com/" className="footer_button" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                  <li ><a href="https://www.linkedin.com/" className="footer_button" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                </ul>
              </div>
              <div className="footer__links-col">
                <h6 className="footer__links-title">Kontaktujte nás</h6>
                <a href="mailto:info@papcraft.com" className="footer_button">info@papcraft.com</a>
              </div>
            </div>
          </div>
          <p className="concatinate ">
            ©2024 <span>Vytvořil <a href="/" className="footer_button">Papcraft</a></span>
          </p>
        </div>
      </footer></>
  );
};

export default Footer;
