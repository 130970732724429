import React, { useEffect, useState } from 'react';
import { Layout, Spin } from 'antd'; // Убираем лишние импорты из antd
import { AppHeader } from './components/AppHeader';
import { Routes, Route, useLocation } from 'react-router-dom';
import SliderEditor from './components/SliderEditor/SliderEditor';
import LearnWords from './components/English/LearnWords';
import Main from './components/Main';
import SliderList from './components/SliderEditor/SliderList';
import "./App.css";
import Footer from './components/Footer';
import Reference from './components/Homepage/Reference';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAuthMe, fetchUserData, fetchRegistration, logout } from './redux/slices/auth';
import GDPR from './components/Homepage/GDPR';

const { Content, Header } = Layout;

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isMainPage = location.pathname === '/';

  const [scrolled, setScrolled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [error, setError] = useState(null);
  const showModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && !isAuthenticated) {
      dispatch(fetchAuthMe()).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          setIsAuthenticated(true); // Пользователь аутентифицирован
          setIsModalOpen(false);
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }

    if (isMainPage) {
      const handleScroll = () => {
        const element = document.querySelector(`.particles-main`);
        if (element) {
          if (window.scrollY > element.offsetHeight) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [dispatch, isMainPage]);

  const registration = async (values) => {
    const data = await dispatch(fetchRegistration(values));
    if (data.payload) {
      setIsAuthenticated(true);
      setIsModalOpen(false);
    }
    console.log(data);
    if(data.error) {
      setError("Uživatel s tímto emailem již existuje");
    }
  };

  const onFinish = async (values) => {
    const data = await dispatch(fetchUserData(values));
    if (data.payload) {
      setIsAuthenticated(true);
      setIsModalOpen(false);
      setError(null);
    }
    if(data.error) {
      setError("Špatný email nebo heslo");
    }
  };

  const exit = () => {
    dispatch(logout());
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigate('/'); 
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Layout style={{ minHeight: '100vh', background: "#f9f9ff"}}>
      <Header
        className={!isAuthenticated && !scrolled ? 'header' : 'header scrolled'}
        style={{
          position: 'sticky',
          top: 0,
          zIndex: scrolled ? 3 : 1,
          width: '100%',
          transition: 'background-color 0.3s ease',
        }}
      >
        <AppHeader
          scrolled={scrolled}
          isAuthenticated={isAuthenticated}
          registration={registration}
          onFinish={onFinish}
          exit={exit}
          isModalOpen={isModalOpen}
          modalType={modalType}
          showModal={showModal}
          handleCancel={handleCancel}
          error={error}
        />
      </Header>
      <Content className={!isAuthenticated ? '' : 'mainContent'}>
        <Routes>
          <Route path="/" element={<Main isAuthenticated={isAuthenticated} />} />
          <Route path="/slider/:id" element={<SliderEditor />} />
          <Route path="/learn-words" element={<LearnWords />} />
          <Route path="/sliders" element={<SliderList />} />
          <Route path="/reference" element={<Reference />} />
          <Route path="/GDPR" element={<GDPR />} />
        </Routes>
      </Content>
      <Footer />
    </Layout>
  );
};

export default App;
