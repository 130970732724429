import React from 'react';
import PluginList from './PluginList';
import WelcomePage from './Homepage/WelcomePage';

const Main = ({isAuthenticated}) => {
  return (
    <div>
      {isAuthenticated ? <PluginList /> : <WelcomePage />}
    </div>
  );
}

export default Main;
